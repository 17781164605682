<template>
  <div>
    <b-row>
      <b-col col="12" v-if="active_role == 'corporate'">
        <tracking-corporate></tracking-corporate>
      </b-col>
      <b-col col="12" v-else-if="active_role == 'superadmin'">
        <tracking-superadmin></tracking-superadmin>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow, BCol
  } from 'bootstrap-vue'

  import TrackingCorporate from './tracking/TrackingCorporate.vue'
  import TrackingSuperadmin from './tracking/TrackingSuperadmin.vue'

  export default {
    components: {
      BRow, BCol,
      TrackingCorporate, TrackingSuperadmin
    },
    data() {
      return {
        active_role: null
      }
    },
    created() {
      this.active_role = localStorage.getItem('role')
      this.checkAuth()
    }
  }
</script>