<template>
  <div>
    <b-overlay
      :show="overlay_partner"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card
        title="Data Member"
        no-body
        :aria-hidden="overlay_partner ? true : null"
      >
        <b-card-body>
          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-2"
              @click="exportPartner"
            >
              Download Excel
            </b-button>
          </div>
          
          <div class="d-flex justify-content-end flex-wrap">
            <b-form-group
              label="Filter Date"
              label-size="sm"
              label-for="filterDate"
              class="mb-0 ml-2"
            >
              <b-input-group size="sm">
                <flat-pickr
                  id="filterDate"
                  v-model="filter_date"
                  class="form-control"
                  :config="{ mode: 'range'}"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Sort"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-md-0 ml-2"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    ASC
                  </option>
                  <option :value="true">
                    DESC
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Filter"
              label-size="sm"
              label-for="filterBy"
              class="mb-0 ml-2"
            >
              <b-input-group size="sm">
                <b-form-select
                  v-model="partner"
                  :options="options_partner"
                  id="filterBy"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Search"
              label-size="sm"
              label-for="filterInput"
              class="mb-0 ml-2"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="itemsFiltered"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(name)="data">
            <div class="d-flex flex-row">
              <b-avatar v-if="data.item.logo !== null"
                class="mr-50"
                :src="data.item.image"
              /> 
              <b-avatar v-else
                class="mr-50"
                variant="primary"
                :text="data.item.name | initial"
              />
              <div class="d-flex flex-column">
                <p class="m-0">{{ data.item.name }}</p>
                <small class="text-muted">
                  {{ data.item.email }}
                </small>
              </div>
            </div>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
  import {
    BCardBody, BCard,
		BTable, BAvatar, BBadge, VBModal,
		BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, 
		BDropdown, BDropdownItem, BOverlay
  } from 'bootstrap-vue'
  import flatPickr from 'vue-flatpickr-component'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BCardBody, BCard,
      BTable, BAvatar, BBadge, VBModal,
      BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, 
      BDropdown, BDropdownItem, BOverlay,
      flatPickr
    },
		directives: {
			Ripple,
		},
    data() {
      return {
        perPage: 50,
				pageOptions: [10, 50, 100],
				totalRows: 1,
				currentPage: 1,
				sortBy: '',
				sortDesc: false,
				sortDirection: 'asc',
				filter: null,
				filterOn: [],
				fields: [
          { key: 'name', label: 'User Name', sortable: true },
					{ key: 'id_number', label: 'Member ID', sortable: true },
					{ key: 'partner', label: 'Partner', sortable: true },
					{ key: 'periode_text', label: 'Tanggal', sortable: true }
        ],
				items: [],
				overlay_partner: true,
        active_role: null,

        options_partner: [
          { value: "all", text: "All Partner" }
        ],
        partner: "all",
        filter_date: null,
        start_date: null,
        end_date: null
      }
    },
    created() {
      this.active_role = localStorage.getItem('role')

      this.checkAuth()

      this.getPartnerMember()
      this.getPartnerAll()
    },
		computed: {
			sortOptions() {
				// Create an options list from our fields
				return this.fields
					.filter(f => f.sortable)
					.map(f => ({ text: f.label, value: f.key }))
			},
      itemsFiltered() {
        let items = this.items

        if(this.filter_date) {
          let date = this.filter_date.split(" to ")
          this.start_date = this.formatDate(new Date(date[0]))
          this.end_date = null

          if(date[1] !== undefined) {
            this.end_date = this.formatDate(new Date(date[1]))
          }

          items = this.items.filter(item => {
            let date_item = this.formatDate(new Date(item.periode))

            if(date[1] !== undefined) {
              return (date_item >= this.start_date && date_item <= this.end_date);
            } else {
              return date_item.getTime() === this.start_date.getTime()
            }
          })
        }

        if(this.partner !== "all") {
          items = items.filter(item => {
            return item.id_partner == this.partner
          })
        }

        this.totalRows = items.length

        return items
      }
		},
    methods: {
			onFiltered(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = this.itemsFiltered
				this.currentPage = 1
			},
      async getPartnerMember() {
        let cid = localStorage.getItem('client_id')
        let dataAPI = null
        const config = {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }
        dataAPI = await this.$axios.get(`${cid}/partner/member`, config)
        
        this.items = dataAPI.data.data

				// Set the initial number of items
				this.totalRows = this.items.length

				this.overlay_partner = false
      },
      async getPartnerAll() {
        let cid = localStorage.getItem('client_id')
        
        let dataAPI = null
        if(this.active_role == 'corporate') {
          const config = {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          }
          dataAPI = await this.$axios.get(`${cid}/partner`, config)
        }

        // define partner data
        let partner_temp = []
        dataAPI.data.data.parent.forEach(item => {
          let text_partner = ""
          if(item.name.length > 25) {
            text_partner = `${item.name.substring(0, 25)}...`
          } else {
            text_partner = item.name
          }

          let partner = {
            value: item.id,
            text: text_partner
          }
          
          partner_temp.push(partner)
        })

        // sort partner data
        partner_temp.sort((a, b) => {
          const text_A = a.text.toUpperCase()
          const text_B = b.text.toUpperCase()

          if (text_A < text_B) {
            return -1;
          }

          if (text_A > text_B) {
            return 1;
          }
        })

        // merge 2 data
        this.options_partner = this.options_partner.concat(partner_temp)
      },
      exportPartner() {
        let cid = localStorage.getItem('client_id')
        const config = {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
          responseType: 'blob'
        }

        let start_date = null
        let end_date = null
        if(this.start_date) {
          start_date = `${this.formatDateText(this.start_date)}`
        }

        if(this.end_date) {
          end_date = `${this.formatDateText(this.end_date)}`
        }

        let send = {
          filter_name: this.filter,
          partner: this.partner,
          sort_by: (this.sortBy == "") ? null : this.sortBy,
          sort_type: (this.sortDesc) ? 'desc' : 'asc',
          start_date: start_date,
          end_date: end_date,
        }

        this.$axios.post(`${cid}/partner/export`, send, config)
          .then(res => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/xlsx' }));
            var fURL = document.createElement('a');

            fURL.href = fileURL;
            fURL.setAttribute('download', 'file.xlsx');
            document.body.appendChild(fURL);

            fURL.click();
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatDate(date) {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
      },
      formatDateText(date_full) {
        let year = date_full.getUTCFullYear()
        let month = date_full.getMonth() + 1
        let date = date_full.getDate()

        month = month.toString()
        month = month.padStart(2, '0')

        date = date.toString()
        date = date.padStart(2, '0')

        return `${year}-${month}-${date}`
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>