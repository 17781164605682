<template>
  <section id="feather-icons">
    <div class="icon-search-wrapper my-3 mx-auto">
      <b-form-group>
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="icons-search"
            placeholder="Search Corporate..."
            v-model="search_keyword"
          />
        </b-input-group>
      </b-form-group>
    </div>
    <b-row>
      <b-col cols="12" md="3" v-for="(item, index) in corporate_temp" :key="index">
        <b-card style="height: 80%; cursor: pointer" @click="goToDetail(item.id)">
          <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-column">
              <h1>{{ item.total_redeem }}</h1>
              <small>{{ item.corporate_name }}</small>
            </div>
            <b-avatar
              size="lg"
              class="mr-50"
              :src="item.corporate_logo"
            /> 
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import {
    BRow, BCol,
    BCard, BCardText,
    BFormGroup, BFormInput, 
    BInputGroup, BInputGroupPrepend, 
    BAvatar
  } from 'bootstrap-vue'

  export default {
    components: {
      BRow, BCol,
      BCard, BCardText,
      BFormGroup, BFormInput, 
      BInputGroup, BInputGroupPrepend, 
      BAvatar
    }, 
    data() {
      return {
        search_keyword: null,
        corporate: [],
        corporate_temp: [] // untuk menempatkan hasil modifikasi corporate
      }
    },
    watch: {
      'search_keyword'(newValue, oldValue) {
        this.searchCorporate(newValue)
      },
      flush: 'post'
    },
    created() {
      this.getCorporateTracking()
    },
    methods: {
      async getCorporateTracking() {
        let cid = localStorage.getItem('client_id')
        let dataAPI = null
        const config = {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }
        dataAPI = await this.$axios.get(`${cid}/partner/corporate`, config)

        this.corporate = dataAPI.data.data
        this.corporate_temp = dataAPI.data.data
      },
      searchCorporate(key) {
        let data = this.corporate

        this.corporate_temp = data.filter((item) => {
          return item.corporate_name.toLowerCase().includes(key)
        })
      },
      goToDetail(id) {
        this.$router.push({ path: `/partner-tracking/${id}` })
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/ui-feather.scss';
</style>